<template>
  <el-card class="info_card">
    <div slot="header">
      <span>分子性质信息管理</span>
    </div>
    <PropertieRecordNotAuditRecordSelect ref="PropertieRecordNotAuditRecordSelect"/>
  </el-card>
</template>

<script>
import PropertieRecordNotAuditRecordSelect from "./PropertieRecordNotAuditRecordSelect/index.vue"
export default {
  components:{PropertieRecordNotAuditRecordSelect},
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    getInfo() {
      this.$refs.PropertieRecordNotAuditRecordSelect.getInfo();
    },
  },
};
</script>

<style scoped>
</style>