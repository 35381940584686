<template>
  <div>
    <el-table v-loading="loading" :data="infoList" border style="width: 100%">
      <el-table-column prop="name" label="分子名称" width="180">
      </el-table-column>
      <el-table-column prop="molecular_formula" label="分子式" width="180">
      </el-table-column>
      <el-table-column prop="cas_number" label="CAS号码"></el-table-column>
      <el-table-column prop="pub_chem_cid" label="CID"></el-table-column>
      <el-table-column prop="edit_user_name" label="编辑人"></el-table-column>
      <el-table-column prop="edit_time" label="编辑时间">
        <template slot-scope="scope">
          <span>{{ timestampToTime(scope.row.edit_time) }}</span>
        </template></el-table-column
      >
      <el-table-column prop="status" label="状态" width="100"
        ><template slot-scope="scope">
          <el-tag
            :type="
              scope.row.status == 1
                ? 'info'
                : scope.row.status == 2
                ? 'success'
                : scope.row.status == 3
                ? 'warning'
                : 'danger'
            "
            >{{
              scope.row.status == 1
                ? "待审核"
                : scope.row.status == 2
                ? "已通过"
                : scope.row.status == 3
                ? "未通过"
                : "已下线"
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-if="scope.row.status == 1 || scope.row.status == 3"
            type="primary"
            @click="handleAudit(scope.$index, scope.row)"
            >审核</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <PropertieRecordNotAuditRecordAudit ref="PropertieRecordNotAuditRecordAudit"  @getInfo="getInfo"/>
  </div>
</template>

<script>
import PropertieRecordNotAuditRecordAudit from "../PropertieRecordNotAuditRecordAudit/index.vue"
export default {
  name: "PropertieRecordSelect",
  components:{PropertieRecordNotAuditRecordAudit},
  data() {
    return {
      loading: false,
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      infoList: [],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.loading = true;
      this.$store
        .dispatch("propertieRecord/selectNotAudit", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
        })
        .then((res) => {
          var { data } = res;
          this.infoList = data.info_list;
          this.total = data.total;
          this.loading = false;
        });
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    handleAudit(index,row){
      this.$refs.PropertieRecordNotAuditRecordAudit.handleOpen(row)
    }
  },
};
</script>

<style>
</style>