var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.infoList,"border":""}},[_c('el-table-column',{attrs:{"prop":"name","label":"分子名称","width":"180"}}),_c('el-table-column',{attrs:{"prop":"molecular_formula","label":"分子式","width":"180"}}),_c('el-table-column',{attrs:{"prop":"cas_number","label":"CAS号码"}}),_c('el-table-column',{attrs:{"prop":"pub_chem_cid","label":"CID"}}),_c('el-table-column',{attrs:{"prop":"edit_user_name","label":"编辑人"}}),_c('el-table-column',{attrs:{"prop":"edit_time","label":"编辑时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.timestampToTime(scope.row.edit_time)))])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"状态","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.status == 1
              ? 'info'
              : scope.row.status == 2
              ? 'success'
              : scope.row.status == 3
              ? 'warning'
              : 'danger'}},[_vm._v(_vm._s(scope.row.status == 1 ? "待审核" : scope.row.status == 2 ? "已通过" : scope.row.status == 3 ? "未通过" : "已下线"))])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status == 1 || scope.row.status == 3)?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.handleAudit(scope.$index, scope.row)}}},[_vm._v("审核")]):_vm._e()]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.pageNum,"page-sizes":[5, 10, 15, 20],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),_c('PropertieRecordNotAuditRecordAudit',{ref:"PropertieRecordNotAuditRecordAudit",on:{"getInfo":_vm.getInfo}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }