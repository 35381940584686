<template>
  <el-dialog
    title="审核分子性质"
    :visible="dialogAuditFormVisible"
    width="70rem"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-divider>分子基本信息</el-divider>
    <el-form :model="form" :rules="rules" ref="form">
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="分子名称"
            :label-width="formLabelWidth"
            prop="name"
          >
            <el-input
              readonly
              v-model="form.name"
              placeholder="无分子名称"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="分子式"
            :label-width="formLabelWidth"
            prop="molecular_formula"
          >
            <el-input
              readonly
              v-model="form.molecular_formula"
              placeholder="无分子式"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="CAS号码"
            :label-width="formLabelWidth"
            prop="cas_number"
          >
            <el-input
              readonly
              v-model="form.cas_number"
              placeholder="无CAS号码"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="CID"
            :label-width="formLabelWidth"
            prop="pub_chem_cid"
          >
            <el-input
              readonly
              v-model="form.pub_chem_cid"
              placeholder="无CID"
            ></el-input> </el-form-item></el-col
      ></el-row>
    </el-form>
    <el-divider>分子性质</el-divider>
    <el-row type="flex" justify="space-between" :gutter="5"
      ><el-col
        ><el-card
          ><div slot="header"><b>原性质</b></div>
          <div
            class="propertie_pannel"
            v-html="form.old_content"
          ></div></el-card></el-col
      ><el-col
        ><el-card
          ><div slot="header"><b>新性质</b></div>
          <div
            class="propertie_pannel"
            v-html="form.new_content"
          ></div></el-card></el-col
    ></el-row>
    <el-row class="remark_row">
      <el-card>
        <div slot="header"><b>分子性质修改备注</b></div>
        <el-input
          readonly
          type="textarea"
          rows="3"
          v-model="form.remark"
          maxlength="255"
          show-word-limit
        >
        </el-input>
      </el-card>
    </el-row>
    <el-row class="remark_row">
      <el-card>
        <div slot="header"><b>审核意见</b></div>
        <el-input
          type="textarea"
          rows="3"
          placeholder="请输入审核意见"
          v-model="remark"
          maxlength="255"
          show-word-limit
        >
        </el-input>
      </el-card>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleAuditCancel()" icon="el-icon-circle-close"
        >取 消</el-button
      >
      <el-button
        type="danger"
        :loading="isLoading"
        @click="handleAudit(3)"
        icon="el-icon-remove-outline"
        >驳回</el-button
      >
      <el-button
        type="success"
        :loading="isLoading"
        @click="handleAudit(2)"
        icon="el-icon-circle-check"
        >通过</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "PropertieRecordAudit",
  data() {
    return {
      dialogAuditFormVisible: false,
      isLoading: false,
      formLabelWidth: "5rem",
      //审核意见
      remark: "",
      form: {},
      rules: {},
    };
  },
  methods: {
    handleOpen(data) {
      this.dialogAuditFormVisible = true;
      this.form = data;
    },
    //保存审核的信息
    handleAudit(status) {
      var statusText = status == 2 ? "通过" : "驳回";
      this.$confirm("确认" + statusText + "？")
        .then((_) => {
            console.log(this.form);
          this.$store
            .dispatch("propertieRecordAuditRecord/add", {
              propertie_record_id: this.form.propertie_record_id,
              old_propertie_record_id: this.form.old_propertie_record_id,
              status: status,
              remark: this.remark,
            })
            .then((res) => {
              this.$emit("getInfo");
              this.$message.success(res.msg);
              this.handleAuditCancel();
            })
            .catch(() => {
              this.$message.error("保存失败！");
            });
        })
        .catch((_) => {});
    },
    handleAuditCancel() {
      this.dialogAuditFormVisible = false;
      this.remark = ""
    },
  },
};
</script>

<style scoped>
.propertie_pannel {
  height: 7rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
.remark_row {
  margin-top: 2rem;
}
</style>